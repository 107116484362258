import "@progress/kendo-theme-material/dist/all.css";
import { Fragment, Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import RootComponent from "./components/home";
import Pace from "./components/shared/Pace";
import Notify from "./components/shared/wrappers/notify";
import store, { persistor } from "./store";

// eslint-disable-next-line @typescript-eslint/naming-convention
function App(): JSX.Element {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASEPATH}>
      <Pace color="#79e0fc" />
      <Suspense fallback={<Fragment />}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RootComponent />
            <Notify />
          </PersistGate>
        </Provider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
