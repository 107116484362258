import { get, ApiResponse, post } from "../helpers";

const getTranslations = async (
    selectedLanguage: string
): Promise<ApiResponse<Record<string, string>>> => {
    return get<Record<string, string>>(
        "/Localisation?locale=" + selectedLanguage
    );
};

const addTranslation = async ( 
    resource: { id: string,
        defaultMessage: string }    
): Promise<ApiResponse<boolean>> => {
    return post<{id: string,
        defaultMessage: string }, boolean>("/Localisation", resource);
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const LocalisationApi = { getTranslations, addTranslation };

export default LocalisationApi;