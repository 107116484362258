import { useState } from "react";

export default function useToken(): {
  setToken: (userToken: { token: string }) => void;
  token: string;
  getToken: () => string;
} {
  const getToken = (): string => {
    let tokenString: string | null = sessionStorage.getItem("token");
    if (tokenString == null) tokenString = "{}";
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: { token: string }) => {
    sessionStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
    getToken,
  };
}
