// #region Imports
import React from "react";
import { Route, RouteProps } from "react-router-dom";
// #endregion

// #region Component
// eslint-disable-next-line @typescript-eslint/naming-convention
export default function UnauthenticatedRoute(props: RouteProps): JSX.Element {
    const { children, ...rest } = props;
    return <Route {...rest}>{children}</Route>;
}
// #endregion