import { UserEndPoints } from "./configEndPoints";
import { post, ApiResponse } from "../helpers";
import {
  RegistrationDetail,
} from "../models/registrationModel";
import {
  CampaignDetails,
  UpdatePassword,
  UserDetails,
  UserGroupHierarchy,
} from "../models/userModel";

const getLoggedInUser = async (): Promise<ApiResponse<UserDetails>> => {
  return post<string, UserDetails>(UserEndPoints.GetLoggedInUser, "");
};

const updatePortalIdentity = async (
  userPortalIdentityDetails: RegistrationDetail
): Promise<ApiResponse<string>> => {
  return post<RegistrationDetail, string>(
    UserEndPoints.UpdatePortalIdentity,
    userPortalIdentityDetails
  );
};

const updatePasswordHash = async (
  updatePassword: UpdatePassword
): Promise<ApiResponse<string>> => {
  return post<UpdatePassword, string>(
    UserEndPoints.UpdatePasswordHash,
    updatePassword
  );
};

const resetPassword = async (
  username: string
): Promise<ApiResponse<string>> => {
  return post<string, string>(UserEndPoints.ResetPassword, username);
};

const createNewParticipant = async (
  userPortalIdentityDetails: RegistrationDetail
): Promise<ApiResponse<boolean>> => {
  return post<RegistrationDetail, boolean>(
    UserEndPoints.CreateNewParticipant,
    userPortalIdentityDetails
  );
};

const editParticipant = async (
  userPortalIdentityDetails: RegistrationDetail
): Promise<ApiResponse<boolean>> => {
  return post<RegistrationDetail, boolean>(
    UserEndPoints.EditParticipant,
    userPortalIdentityDetails
  );
};

const getUserGroupHierarchy = async (): Promise<
  ApiResponse<UserGroupHierarchy[]>
> => {
  return post<string, UserGroupHierarchy[]>(
    UserEndPoints.GetUserGroupHierarchy,
    ""
  );
};

const saveCampaignDetails = async (
  campaignDetails: CampaignDetails
): Promise<ApiResponse<boolean>> => {
  return post<CampaignDetails, boolean>(
    UserEndPoints.SaveCampaignDetails,
    campaignDetails
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const UserApi = {
  getLoggedInUser,
  updatePortalIdentity,
  updatePasswordHash,
  resetPassword,
  createNewParticipant,
  editParticipant,
  getUserGroupHierarchy,
  saveCampaignDetails,
};

export default UserApi;
