import React from "react";

// eslint-disable-next-line @typescript-eslint/naming-convention
const LanguageContext = React.createContext({
  locale: "en",
  updateLocale: (locale: string, clientName: string) => {
    // console.log(locale);
  },
});

export default LanguageContext;
