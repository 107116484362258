import { useState } from "react";

export default function useDirectAccess(): {
    setDirectAccess: (userDirectAccess: { isDirectAccess: boolean }) => void;
    getDirectAccess: () => boolean;
    isDirectAccess: boolean;
} {
    const getDirectAccess = (): boolean => {
        let isDirectAccessString: string | null = sessionStorage.getItem("isDirectAccess");
        if (isDirectAccessString === null) isDirectAccessString = "false"; //Set to false by default 
        const userDirectAccess = JSON.parse(isDirectAccessString);
        return userDirectAccess; 
    };

    const [isDirectAccess, setIsDirectAccess] = useState(getDirectAccess());

    const saveDirectAccess = (userDirectAccess: { isDirectAccess: boolean }) => {
        sessionStorage.setItem("isDirectAccess", JSON.stringify(userDirectAccess));
        setIsDirectAccess(userDirectAccess.isDirectAccess);
    };

    return {
        setDirectAccess: saveDirectAccess,
        getDirectAccess: getDirectAccess,
        isDirectAccess,
    };
}