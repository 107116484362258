// #region Imports
import { Slide } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { useEffect } from "react";
import { hideNotification } from "../../../appSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
// #endregion

// #region Declarations
export type NotificationDetails = {
  show?: boolean;
  notificationMessage: string;
  notificationType: NotificationType;
  notificationErrorMessage?: string[];
};
export type NotificationType =
  | "none"
  | "success"
  | "error"
  | "warning"
  | "info";

// #endregion

// #region Component
// eslint-disable-next-line @typescript-eslint/naming-convention
export default function Notify(): JSX.Element {
  const notificationDetails =
    useAppSelector((t) => t.app.notificationDetails) ?? "";
  const dispatch = useAppDispatch();
  useEffect(() => {
    let isMounted = true; // mutable flag
    if (notificationDetails.show) {
      setTimeout(() => {
        if (isMounted) {
          dispatch(hideNotification());
        }
      }, 3000);
    } else {
      dispatch(hideNotification());
    }
    return () => {
      isMounted = false;
    }; // use cleanup to toggle mounted value
  }, [notificationDetails.show, dispatch]);
  //const show = true;

  return (
    <div id="notification" role="alert">
      <NotificationGroup className="topCenterNotification">
        {/* {show && ( */}
        <Slide direction={notificationDetails.show ? "down" : "up"}>
          {notificationDetails.show && (
            <Notification
              type={{ style: notificationDetails.notificationType, icon: true }}
            >
              {notificationDetails.notificationMessage}
              {notificationDetails.notificationErrorMessage?.map((item) => {
                return <p>{item}</p>;
              })}
            </Notification>
          )}
        </Slide>
        {/* )} */}
      </NotificationGroup>
    </div>
  );
}
// #endregion
