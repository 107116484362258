/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConsentEndPoints } from "./configEndPoints";
import { post, ApiResponse } from "../helpers";
import { FormFieldItemValues } from "../models/formRenderModel";
import { DocumentUpload } from "../models/fileUploadModel";

const getConsentPdf = async (
  identityId: number
): Promise<ApiResponse<DocumentUpload>> => {
  return post<number, DocumentUpload>(ConsentEndPoints.GetConsentPdf, identityId);
};

const getConsentFormItems = async (
  consentVersionId: number
): Promise<ApiResponse<FormFieldItemValues>> => {
  return post<number, FormFieldItemValues>(ConsentEndPoints.GetConsentFormItems, consentVersionId);
};

const documentViewer = async (
  pkid: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<ApiResponse<any>> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return post<number, any>(ConsentEndPoints.DocumentViewer, pkid);
};

const downloadConsentPdfFile = async (): Promise<ApiResponse<any>> => {
  return post<string, any>(ConsentEndPoints.DownloadConsentPdfFile, "");
};

const checkExistConsentPdfFile = async (): Promise<ApiResponse<boolean>> => {
  return post<string, boolean>(ConsentEndPoints.CheckExistConsentPdfFile, "");
};
// eslint-disable-next-line @typescript-eslint/naming-convention
const ConsentApi = {
  getConsentPdf,
  getConsentFormItems,
  documentViewer,
  downloadConsentPdfFile,
  checkExistConsentPdfFile,
};

export default ConsentApi;
