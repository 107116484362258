import { SurveyEndPoints } from "./configEndPoints";
import { post, ApiResponse } from "../helpers";
import {
  KeyValue,
  PagingInfo,
  PagingInfoWithId,
  TimeLineData,
} from "../models/commonModel";
import {
  ClinicianAssessmentRequest,
  SurveyCompleted,
  SurveyCompletedRequest,
  SurveyDue,
  SurveyDueRequest,
  SurveyTimelineRequest,
  VisitWindow,
  CommentReview,
} from "../models/surveyModel";
const getSurveyDue = async (
  pagingInfo: PagingInfo,
  request: SurveyDueRequest
): Promise<ApiResponse<SurveyDue[]>> => {
  return post<SurveyDueRequest, SurveyDue[]>(SurveyEndPoints.GetSurveyDue, {
    ...request,
    ...pagingInfo,
  });
};
const getSurveyCompletedList = async (
  pagingInfo: PagingInfo,
  request: SurveyCompletedRequest
): Promise<ApiResponse<SurveyCompleted[]>> => {
  return post<SurveyCompletedRequest, SurveyCompleted[]>(
    SurveyEndPoints.GetSurveyCompleted,
    {
      ...pagingInfo,
      ...request,
    }
  );
};
const getSurveyTimeline = async (
  surveyTimelineRequest: SurveyTimelineRequest
): Promise<ApiResponse<TimeLineData>> => {
  return post<SurveyTimelineRequest, TimeLineData>(
    SurveyEndPoints.GetSurveyTimeline,
    surveyTimelineRequest
  );
};
const getClinicianAssessments = async (
  pagingInfo: PagingInfo,
  request: ClinicianAssessmentRequest
): Promise<ApiResponse<SurveyDue[]>> => {
  return post<ClinicianAssessmentRequest, SurveyDue[]>(
    SurveyEndPoints.GetClinicianAssessments,
    { ...request, ...pagingInfo }
  );
};

const getVisitWindows = async (
  identityId: number
): Promise<ApiResponse<VisitWindow[]>> => {
  return post<number, VisitWindow[]>(
    SurveyEndPoints.GetVisitWindows,
    identityId
  );
};

const saveCommentReview = async (
  commentReview: CommentReview
): Promise<ApiResponse<boolean>> => {
  return post<CommentReview, boolean>(
    SurveyEndPoints.SaveCommentReview,
    commentReview
  );
};

const getSurveyListForReports = async (): Promise<ApiResponse<KeyValue[]>> => {
  return post<string, KeyValue[]>(SurveyEndPoints.GetSurveyListForReports, "");
};

const getSubmissions = async (
  pagingInfo: PagingInfo,
  id: number
): Promise<ApiResponse<SurveyCompleted[]>> => {
  return post<PagingInfoWithId, SurveyCompleted[]>(
    SurveyEndPoints.GetSubmissions,
    {
      ...pagingInfo,
      id,
    }
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const SurveyApi = {
  getSurveyDue,
  getSurveyCompletedList,
  getSurveyTimeline,
  getVisitWindows,
  getClinicianAssessments,
  saveCommentReview,
  getSurveyListForReports,
  getSubmissions,
};

export default SurveyApi;
