import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CommentReviewReportFilterReduxModel,
} from "../../../libs/models/reportModel";

const initialState: CommentReviewReportFilterReduxModel = {
  scoreCardId: 0,
  startDate: new Date(),
  endDate: new Date(),
  weekSelection: "",
  hierarchyId: 0
};

const reportSearchFilterSlice = createSlice({
  name: "reportSearchFilterState",
  initialState,
  reducers: {
    onDateValueSelection: (state, action: PayloadAction<string>) => {
      state.weekSelection = "";
    },
    onReportSearchFilterSubmit: (state, action: PayloadAction<CommentReviewReportFilterReduxModel>) => {
      const actionData = action.payload;
      state.scoreCardId = actionData.scoreCardId ?? state.scoreCardId;
      state.startDate = actionData.startDate ?? state.startDate;
      state.endDate = actionData.endDate ?? state.endDate;
      state.weekSelection = actionData.weekSelection ?? state.weekSelection;
      state.hierarchyId = actionData.hierarchyId ?? state.hierarchyId;
    }
  },
});
export const { onReportSearchFilterSubmit, onDateValueSelection } =
  reportSearchFilterSlice.actions;
export default reportSearchFilterSlice.reducer;
