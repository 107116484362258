import { useIntl } from "react-intl";
import { TranslationItem } from "../models/commonModel";

export default function useLocalisation(): {
    formatMessage: (translationItem: TranslationItem) => string;
} {
    const intl = useIntl();
    const formatMessage = (translationItem: TranslationItem) => {
        return intl.formatMessage({ ...translationItem });
    };
    return { formatMessage: formatMessage };
}