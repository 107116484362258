import { useAppSelector } from "../../store";
import { useClientAppSettings } from ".";

export default function useLanguage(): {
  getSiteLanguage: () => string;
  getSiteLanguageByAppName: (appName: string) => string;
} {
  const { getClientAppSettings } = useClientAppSettings();
  const app = getClientAppSettings();

  // Get all siteLanguage collection from redux 
  const siteLanguage =
    useAppSelector(
      (state) =>
        state.app.siteLanguage
    ) ?? [];
  
  // Gets the site language and AppName is based on parameter 
 const getSiteLanguageByAppName = (appName: string) => {
    return getSiteLanguageFormRedux(appName);
  };

  // Gets the site language and AppName is calculated form session store 
  const getSiteLanguage = (): string => {
    return getSiteLanguageFormRedux(app.AppName ?? "");
  };

  // Get he value from redux
  const getSiteLanguageFormRedux = (appName: string): string => {
    return siteLanguage.find(
          (t) => t.key ===  appName.toLowerCase() ?? ""
        )?.value ?? "";
  };

  return {
    getSiteLanguage: getSiteLanguage,
    getSiteLanguageByAppName: getSiteLanguageByAppName,
  };
}
