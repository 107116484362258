/* eslint-disable @typescript-eslint/no-explicit-any */
// #region Imports
import { useEffect, useRef, useState } from "react";
import { IntlProvider } from "react-intl";
import { matchPath, RouteComponentProps, withRouter } from "react-router-dom";
import {
  setPortalDefaultLanguage,
  setSiteLanguage,
  setTheme,
} from "../../appSlice";
import LanguageContext from "../../libs/context/LanguageContext";
import { useClientAppSettings, useLanguage, useToken } from "../../libs/hooks";
import { ClientDetails } from "../../libs/models/accountModel";
import { AccountApi, LocalisationApi } from "../../libs/services";
import { useAppDispatch } from "../../store";
import IntlProviderForKendoControls from "../intl/intlProviderForKendoControls";
import { ApplicationRoutes } from "../routes/config";
import Routes from "../routes/routes";

// import { isDevelopment } from "../../libs/helpers";
// #endregion

// #region Component
// eslint-disable-next-line @typescript-eslint/naming-convention
function RootComponent(props: RouteComponentProps): JSX.Element {
  const dispatch = useAppDispatch();

  // state
  const defaultMessages: Record<string, string> = {};
  const [messages, setMessages] = useState(defaultMessages);
  const { app, setClientApp } = useClientAppSettings();
  const activeAppName = useRef<string>("");
  const [activeSiteLanguage, setActiveSiteLanguage] = useState("");
  const isLoginPage = useRef<boolean>(true);
  const isSiteLanguageAvailableInRedux = useRef<boolean>(false); // Is site lang available in redux
  const { getClientAppSettings } = useClientAppSettings();
  const { getSiteLanguageByAppName, getSiteLanguage } = useLanguage();
  const { setToken } = useToken();
  const queryString = props.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tokenFromUrl = urlParams?.get("token") ?? "";
  useEffect(() => {
    if (tokenFromUrl) setToken({ token: tokenFromUrl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Resolve client name from the URL
   * Update the site language if there is already value in redux for site language
   * if it is new client then call setClientApp to create the session
   */
  useEffect(() => {
    let appNameFromUrl = "";

    // client name can either be in the path (http://connection.local:3001/icportal/basenhsdev) or
    // can be in the search query string (http://connection.local:3001/icportal/login?redirect=/BaseNhsDev)
    // if the client name is in the search query string then use that
    if (props.location.search) {
      const urlParams = new URLSearchParams(props.location.search);
      appNameFromUrl = urlParams?.get("redirect")?.replace("/", "") ?? "";
    }
    // if the client name is not in the search query string, then check the path for client name
    // exclude application routes from the check
    else if (
      !Object.values(ApplicationRoutes).some((path) => {
        return matchPath(props.location.pathname, path);
      })
    ) {
      appNameFromUrl = props.location.pathname.replace("/", "");
    }
    //Set the Active app name from URL
    activeAppName.current = appNameFromUrl;

    // Get the current location to determine the if the active page in login
    const activeLocationPathName = window.location.pathname
      .replace("/icportal", "")
      .replace("/portal", "");
    isLoginPage.current =
      activeLocationPathName === ApplicationRoutes.Login.toString() ||
      activeLocationPathName === "/";
    //get the the last saved lang from redux
    //If login page then use the app name from URL else get the value from session
    const siteLangFromRedux = isLoginPage.current
      ? getSiteLanguageByAppName(appNameFromUrl)
      : getSiteLanguage();

    //Set the active lang
    setActiveSiteLanguage(siteLangFromRedux);

    if (appNameFromUrl) {
      // Flag if the lang was found from redux
      isSiteLanguageAvailableInRedux.current = siteLangFromRedux !== "";
      // if the name resolved from the URL is not what we currently have in session
      // then update session
      if (appNameFromUrl.toLowerCase() !== (app.AppName?.toLowerCase() ?? "")) {
        //Init Client to session
        setClientApp({
          AppName: appNameFromUrl,
          ClientName: "",
          ClientFullName: "",
          ClientLogo: "",
          CopyrightYear: "",
          ForceReadOnly: false,
          DisableSelfRegistration: false,
          DatabaseLock: true,
          DatabaseLockText: "",
          siteLanguage: "",
          portalDefaultLanguage: "",
          ShowForgotPasswordLink: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientDetailRef = useRef<ClientDetails>({} as ClientDetails);
  useEffect(() => {
    if (activeAppName.current && activeAppName.current !== "") {
      AccountApi.getClient(activeSiteLanguage).then((res) => {
        if (res.responseCode === 200 && res.data) {
          clientDetailRef.current = res.data as ClientDetails;
          const clientDetail = clientDetailRef.current;
          // by default get language to "en"
          let activeLanguage = "en";

          // use language from browser's setting if available
          if (navigator.language)
            activeLanguage = navigator.language.split(/[-_]/)[0];

          // use portal default language if available
          if (clientDetail.portalDefaultLanguage)
            activeLanguage = clientDetail.portalDefaultLanguage;

          // use user's language if available
          if (activeSiteLanguage !== "") activeLanguage = activeSiteLanguage;

          //update the session store
          setClientApp({
            ...getClientAppSettings(),
            ClientName: clientDetail.name,
            ClientFullName: clientDetail.appName ?? "",
            CopyrightYear: clientDetail.copyrightYear,
            ClientLogo:
              process.env.PUBLIC_URL +
              (clientDetail.clientLogo
                ? "/client-logos/" + clientDetail.clientLogo
                : process.env.PUBLIC_URL + "/AppLogo.png"),
            ForceReadOnly: clientDetail.forceReadOnlyDBLock,
            DisableSelfRegistration: clientDetail.disableSelfRegistration,
            DatabaseLock: clientDetail.databaseLock,
            DatabaseLockText: clientDetail.databaseLockText,
            portalDefaultLanguage: clientDetail.portalDefaultLanguage, //Since this is session specific no need to append client name
            ShowForgotPasswordLink: clientDetail.showForgotPasswordLink,
          });

          // we have a user preferred language then update the language
          // or update the lang based on calculation done above to determine the value from activeLanguage
          if (clientDetail.userPreferredLanguage)
            updateLocale(
              clientDetail.userPreferredLanguage,
              activeAppName.current
            );
          else if (!isSiteLanguageAvailableInRedux.current)
            updateLocale(activeLanguage, activeAppName.current);

          dispatch(
            setPortalDefaultLanguage({
              key: clientDetail.name,
              value: clientDetail.portalDefaultLanguage,
            })
          );

          dispatch(
            setTheme({ key: clientDetail.name, value: clientDetail.theme })
          );
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Get translations from api call whenever the activeLanguage changes and update the state
  */
  useEffect(() => {
    if (activeSiteLanguage !== "") {
      LocalisationApi.getTranslations(activeSiteLanguage).then((res) => {
        if (res.responseCode === 200 && res.data !== undefined) {
          setMessages(res.data);
        }
      });
    }
    
    // Developer only, un-comment when needed to refresh the resource entries
    // insertDefaultResources();
  }, [activeSiteLanguage]);
  /**
   * This method is called for update the language in session and redux and also to update the language resource entries
   * @param selectedLocale  // the selected locale is language value which user has selected
   * @param currentClient  // current client name
   */
  function updateLocale(selectedLocale: string, currentClient: string) {
    if (
      currentClient &&
      currentClient !== "" &&
      selectedLocale &&
      selectedLocale !== ""
    ) {
      //update the session store
      setClientApp({
        ...getClientAppSettings(),
        siteLanguage: selectedLocale, //Since this is session specific no need to append client name
      });

      // Update the redux store
      dispatch(
        setSiteLanguage({
          key: currentClient,
          value: selectedLocale,
        })
      );

      //Update the Active Language to trigger update the language resource entries
      setActiveSiteLanguage(selectedLocale);
    }
  }
  // if locale is like en_US, send only en to the UI as UI works only with the 2 letter ISO code
  const langISOCode =
    activeSiteLanguage.length > 2
      ? activeSiteLanguage.substring(0, 2)
      : activeSiteLanguage;

  // if we do not have the client's full name e.g. IQVIA Technology Services, then redirect to 404
  // this is only possible if the app name is not valid as all valid app names have a full name
  if (clientDetailRef.current.name && !clientDetailRef.current.appName) {
    // do not redirect if we are already on the 404 page
    if (props.location.pathname !== ApplicationRoutes.Portal404)
      props.history.push("/page404");
  }
  return (
    <LanguageContext.Provider
      value={{ locale: activeSiteLanguage, updateLocale: updateLocale }}
    >
      <IntlProviderForKendoControls>
        <IntlProvider locale={langISOCode} messages={messages}>
          <Routes />
        </IntlProvider>
      </IntlProviderForKendoControls>
    </LanguageContext.Provider>
  );
}
export default withRouter(RootComponent);
// #endregion

// #region Functions
// Developer only
// const insertDefaultResources = () => {
//   const getMessagesFromFileAndUpdateBackend = (resourceSet: string) => {
//     const messageLoader = () => import("../../messages/" + resourceSet);
//     messageLoader().then((messages: any) => {
//       const resources = messages.default;
//       let k: keyof typeof resources;
//       for (k in resources) {
//         const v = resources[k];
//         LocalisationApi.addTranslation({
//           id: "Portal." + v.id,
//           defaultMessage: v.defaultMessage,
//         });
//       }
//     });
//   };

//   if (isDevelopment()) {
// getMessagesFromFileAndUpdateBackend("applicationMessages");
// getMessagesFromFileAndUpdateBackend("clinicianAssessmentMessages");
// getMessagesFromFileAndUpdateBackend("commentReviewReportMessages");
// getMessagesFromFileAndUpdateBackend("contactUsMessages");
// getMessagesFromFileAndUpdateBackend("dashboardMessages");
// getMessagesFromFileAndUpdateBackend("fileUploadMessages");
// getMessagesFromFileAndUpdateBackend("forgotPasswordMessages");
// getMessagesFromFileAndUpdateBackend("gasMessages");
// getMessagesFromFileAndUpdateBackend("globalPortalMessages");
// getMessagesFromFileAndUpdateBackend("homeMessages");
// getMessagesFromFileAndUpdateBackend("loginPageMessages");
// getMessagesFromFileAndUpdateBackend("myAccountMessages");
// getMessagesFromFileAndUpdateBackend("myDataMessages");
// getMessagesFromFileAndUpdateBackend("myImpactMessages");
// getMessagesFromFileAndUpdateBackend("newsletterMessage");
// getMessagesFromFileAndUpdateBackend("optOutMessages");
// getMessagesFromFileAndUpdateBackend("participants");
// getMessagesFromFileAndUpdateBackend("participantSearchMessages");
// getMessagesFromFileAndUpdateBackend("registrationMessages");
// getMessagesFromFileAndUpdateBackend("resourcesMessage");
// getMessagesFromFileAndUpdateBackend("setPasswordMessages");
// getMessagesFromFileAndUpdateBackend("surveyMessages");
// getMessagesFromFileAndUpdateBackend("userMessages");
// getMessagesFromFileAndUpdateBackend("validateMessages");
// getMessagesFromFileAndUpdateBackend("viewSubmissions");
//   }
// };
// #endregion
