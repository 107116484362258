import { ApiResponse, post } from "../helpers";
import { PagingInfo, PagingInfoWithId } from "../models/commonModel";
import {
  GasBuilderData,
  GasScoreDetails,
  GasScoreFormData,
  GasSurveyData,
} from "../models/gasModel";
import { GasEndpoints } from "./configEndPoints";

const saveGasBuilderData = async (
  data: GasBuilderData
): Promise<ApiResponse<void>> => {
  return post<GasBuilderData, void>(GasEndpoints.SaveGasBuilderData, {
    ...data,
  });
};

const getGasScoreDetails = async (
  id: number
): Promise<ApiResponse<GasScoreDetails>> => {
  return post<number, GasScoreDetails>(GasEndpoints.GetGasScoreDetails, id);
};

const saveGasScoreRating = async (
  data: GasScoreFormData
): Promise<ApiResponse<boolean>> => {
  return post<GasScoreFormData, boolean>(GasEndpoints.SaveGasScoreRating, data);
};

const getGasBuilderData = async (data: {
  gasSurveyId: number;
identityId: number;
}): Promise<ApiResponse<GasBuilderData>> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return post<any, GasBuilderData>(GasEndpoints.GetGasBuilderData, { ...data });
};

const getParticipantDetails = async (
  identityId: number
): Promise<ApiResponse<string>> => {
  return post<number, string>(GasEndpoints.GetParticipantDetails, identityId);
};

const getGasSurveyData = async (
  pagingInfo: PagingInfo,
  id: number
): Promise<ApiResponse<GasSurveyData[]>> => {
  return post<PagingInfoWithId, GasSurveyData[]>(
    GasEndpoints.GetGasSurveyData,
    {
      ...pagingInfo,
      id,
    }
  );
};
// eslint-disable-next-line @typescript-eslint/naming-convention
const GasApi = {
  saveGasBuilderData,
  getGasBuilderData,
  getParticipantDetails,
  getGasSurveyData,
  getGasScoreDetails,
  saveGasScoreRating,
};

export default GasApi;
