import { useState } from "react";
import { PortalMenuItem } from "../models/accountModel";

export default function usePermittedMenuItems(): {
  setPermittedMenuItems: (permittedMenuItems: {
    permittedMenuItems: Array<PortalMenuItem>;
  }) => void;
  permittedMenuItems: Array<PortalMenuItem>;
} {
  const getPermittedMenuItems = (): Array<PortalMenuItem> => {
    let permittedMenuItemsString: string | null = sessionStorage.getItem(
      "permittedMenuItemsString"
    );
    if (permittedMenuItemsString == null) permittedMenuItemsString = "{}";
    const userPermittedMenuItems = JSON.parse(permittedMenuItemsString);
    return userPermittedMenuItems?.permittedMenuItems;
  };

  const [permittedMenuItems, setPermittedMenuItems] = useState(
    getPermittedMenuItems()
  );

  const savePermittedMenuItems = (userPermittedMenuItems: {
    permittedMenuItems: Array<PortalMenuItem>;
  }) => {
    sessionStorage.setItem(
      "permittedMenuItemsString",
      JSON.stringify(userPermittedMenuItems)
    );
    setPermittedMenuItems(userPermittedMenuItems.permittedMenuItems);
  };

  return {
    setPermittedMenuItems: savePermittedMenuItems,
    permittedMenuItems,
  };
}
