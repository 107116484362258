// #region Imports
import { Suspense, lazy } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import InitialiseClient from "../home/initialiseClient";
import AuthenticatedRoute from "./authenticatedRoute";
import { UnAuthenticatedRoutes } from "./config";
import UnauthenticatedRoute from "./unauthenticatedRoute";
// #endregion

// #region Declarations
// eslint-disable-next-line @typescript-eslint/naming-convention
const HomePage = lazy(() => import("../main/homePage"));
// #endregion

// #region Component
// eslint-disable-next-line @typescript-eslint/naming-convention
export default function Routes(): JSX.Element {
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path="/"
        render={(props: RouteComponentProps) => {          
          return <HomePage key={props.match.path} {...props} />;
        }}
      />
      <AuthenticatedRoute
        path="/portal"
        render={(props: RouteComponentProps) => {
          return <HomePage key={props.match.path} {...props} />;
        }}
      />
      {getUnAuthenticatedRoutes()}
    </Switch>
  );
}
const getUnAuthenticatedRoutes = () => {
  return (
    <Switch>
      {UnAuthenticatedRoutes.map((route) => {
        return (
          <UnauthenticatedRoute
            key={route.path}
            exact
            path={route.path}
            render={(props: RouteComponentProps) => {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              const Component = route.component;
              return (
                <Suspense fallback={<></>}>
                  <Component key={props.match.path} {...props} />
                </Suspense>
              );
            }}
          />
        );
      })}
      {/* Route for client app names, e.g. BaseNhsDev, FFB.
       This should be the last route and is set to match anything after a "/" */}
      <Route
        path="/*"
        render={({ match }) => {          
          return <InitialiseClient matchPath={match.url} />;
        }}
      />
    </Switch>
  );
};
// #endregion
