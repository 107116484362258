import { ApiBaseUrl, UploadFilesEndPoints } from "./configEndPoints";
import { post, ApiResponse } from "../helpers";
import { PagingInfo } from "../models/commonModel";
import {
    DocumentUpload,
    DocumentUploadRequest,
} from "../models/fileUploadModel";

const getDocumentUploadResultList = async (
    pagingInfo: PagingInfo,
    identityId: number
): Promise<ApiResponse<DocumentUpload[]>> => {
    return post<DocumentUploadRequest, DocumentUpload[]>(UploadFilesEndPoints.GetDocumentUploadResult, {
        ...pagingInfo,
        identityId
    });
};

const deleteFile = async (
    pkid: number
): Promise<ApiResponse<string>> => {
    return post<number, string>(UploadFilesEndPoints.DeleteFile, pkid);
};

const documentViewer = async (
    pkid: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<ApiResponse<any>> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return post<number, any>(UploadFilesEndPoints.DocumentViewer, pkid);
};

const userUploadFiles = ApiBaseUrl + UploadFilesEndPoints.UploadFiles;

// eslint-disable-next-line @typescript-eslint/naming-convention
const FileUploadApi = {
    getDocumentUploadResultList,
    deleteFile,
    documentViewer,
    userUploadFiles
};

export default FileUploadApi;