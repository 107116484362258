import { post, ApiResponse } from "../helpers";
import { PagingInfo, PagingInfoWithAnyType } from "../models/commonModel";
import { ReportEndpoints } from "./configEndPoints";
import {
  CommentReviewReportFilterModel,
  CommentReviewReportModel,
} from "../models/reportModel";

const getCommentReviewReport = async (
  pagingInfo: PagingInfo,
  anyParms: CommentReviewReportFilterModel[]
): Promise<ApiResponse<CommentReviewReportModel[]>> => {
  return post<PagingInfoWithAnyType, CommentReviewReportModel[]>(
    ReportEndpoints.GetCommentReviewReport,
    { ...pagingInfo, anyParms }
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ReportApi = {
  getCommentReviewReport,
};

export default ReportApi;
