import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataGridFilterReduxModel } from "../../../libs/models/commonModel";

type DataGridFilterReduxState = {
  gridFilterState: DataGridFilterReduxModel[];
};

const initialState: DataGridFilterReduxState = {
  gridFilterState: [],
};

const dataGridSlice = createSlice({
  name: "dataGridState",
  initialState,
  reducers: {
    onGridColumnFilterChange: (state, action: PayloadAction<DataGridFilterReduxModel>) => {
      upsertGridStateValue(state, action, "filter");
    },
    onGridPageNumberChange: (state, action: PayloadAction<DataGridFilterReduxModel>) => {
      upsertGridStateValue(state, action, "pagingInfo");
    },
  },
});

export const {onGridColumnFilterChange, onGridPageNumberChange } =
  dataGridSlice.actions;

export default dataGridSlice.reducer;

/**
 * This function add/updates the redux state for the grid
 * @param state // Redux State Object
 * @param action // Payload Action
 * @param filedName // FIled name for which the state value needs to be added/updated 
 */
function upsertGridStateValue(state: DataGridFilterReduxState, action: PayloadAction<DataGridFilterReduxModel>, filedName: string) {
  const actionData = action.payload;
  if (actionData[filedName as keyof DataGridFilterReduxModel]) { //Check if there is any value to modify/add for the given filed name
    // Since all the grid stores the values in redux, we have stored it as a collection,
    // This is stored in the form of KEY and value pair for each grid e.g: [KEY, VALUE]
    // Where the key is the grid ID and when we update it, we need to make sure to update it for the correct grid key. 
    // Therefore, we need to find the current key and get its index and update the value   
    const keyIndex = state.gridFilterState.findIndex(
      (t) => t.id === actionData.id?.toLowerCase() ?? ""
    );

    if (keyIndex !== -1) {
      //Update mode
      state.gridFilterState[keyIndex][filedName as keyof DataGridFilterReduxModel] = actionData[filedName as keyof DataGridFilterReduxModel];
    } else {
      //Add mode 
      const newGridState = { "id": actionData.id?.toLowerCase() ?? "" } as DataGridFilterReduxModel;
      newGridState[filedName as keyof DataGridFilterReduxModel] = actionData[filedName as keyof DataGridFilterReduxModel];
      state.gridFilterState.push(newGridState);
    }
  }
}
