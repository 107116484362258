import { IntlProvider, load } from "@progress/kendo-react-intl";
import currencyData from "cldr-core/supplemental/currencyData.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import weekData from "cldr-core/supplemental/weekData.json";
import {
  ReactNode,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import LanguageContext from "../../libs/context/LanguageContext";

// eslint-disable-next-line @typescript-eslint/naming-convention
const IntlProviderForKendoControls = (props: {
  children: Array<ReactNode> | ReactNode;
}): JSX.Element => {
  const [currentLocale, setCurrentLocale] = useState("en");
  let { locale } = useContext(LanguageContext);
  locale = getLocaleToUseForKendoControls(locale);
  // load the locale data dynamically when the locale changes
  useEffect(() => {
    if (locale) {
      Promise.all([
        import("cldr-dates-full/main/" + locale + "/ca-gregorian.json"),
        import("cldr-dates-full/main/" + locale + "/dateFields.json"),
        import("cldr-dates-full/main/" + locale + "/timeZoneNames.json"),
        import("cldr-numbers-full/main/" + locale + "/numbers.json"),
      ]).then(([caGregorian, dateFields, timeZoneNames, numbers]) => {
        load(
          likelySubtags,
          currencyData,
          weekData,
          numbers.default,
          caGregorian.default,
          dateFields.default,
          timeZoneNames.default
        );
        setCurrentLocale(locale);
      });
    }
  }, [locale]);
  // return the IntlProvider with the current locale, memoize the component to avoid re-rendering while the locale is changing
  // otherwise, the kendo controls will throw an error saying that the locale is not loaded
  return useMemo(() => {
    return (
      <>
        <Suspense fallback={<></>}>
          <IntlProvider locale={currentLocale}>
            {/* render the childern nodes */}
            {props.children}
          </IntlProvider>
        </Suspense>
      </>
    );
  }, [props.children, currentLocale]);
};

/**
 * Get the locale to use for Kendo controls. If two characters, e.g "en", then return it as is otherwise convert it to "en-US" format.
 * @param locale  Current site locale.
 * @returns  The locale to use for Kendo controls.
 */
const getLocaleToUseForKendoControls = (locale: string): string => {
  // if locale is greater than 2 characters, e.g "en-us", then convert it to "en-US" format
  // as Kendo controls require the locale to be in this format, i.e the last two characters should be in uppercase
  if (locale.length > 2) {
    const localeParts = locale.split("-");
    locale = localeParts[0] + "-" + localeParts[1].toUpperCase();
  }
  return locale;
};
export default IntlProviderForKendoControls;
