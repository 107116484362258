import { ApiResponse, get, post } from "../helpers";
import {
  IdentityExternalData,
  PortalParameters,
  UserLanguage,
  UserLanguageRequest,
} from "../models/settingModel";
import { MenuItemSettings } from "../models/userModel";
import { SettingEndPoints } from "./configEndPoints";

const getPortalParameterValueByName = async (
  parameterName: string
): Promise<ApiResponse<PortalParameters>> => {
  return post<string, PortalParameters>(
    SettingEndPoints.GetPortalParameterValueByName,
    parameterName
  );
};

const upsertUserSetting = async (
  identityExternalData: IdentityExternalData
): Promise<ApiResponse<string>> => {
  return post<IdentityExternalData, string>(
    SettingEndPoints.UpsertUserSetting,
    identityExternalData
  );
};

const getUserLanguageOptions = async (
  userLanguageRequest: UserLanguageRequest
): Promise<ApiResponse<UserLanguage[]>> => {
  return post<UserLanguageRequest, UserLanguage[]>(
    SettingEndPoints.GetUserLanguageOptions,
    userLanguageRequest
  );
};

const getParticipantRecordSettings = async (): Promise<
  ApiResponse<MenuItemSettings>
> => {
  return get<MenuItemSettings>(SettingEndPoints.GetParticipantRecordSettings);
};

const getLocaleByLanguageId = async (
  languageId: number
): Promise<ApiResponse<string>> => {
  return post<number, string>(
    SettingEndPoints.GetLocaleByLanguageId,
    languageId
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const SettingApi = {
  getPortalParameterValueByName,
  upsertUserSetting,
  getUserLanguageOptions,
  getParticipantRecordSettings,
  getLocaleByLanguageId,
};

export default SettingApi;
