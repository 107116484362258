import { combineReducers } from "@reduxjs/toolkit";
import appSliceReducer from "./appSlice";
import reportSearchFilterSliceReducer from "./components/shared/filters/reportSearchFilterSlice";
import referralRegistrationSliceReducer from "./components/registerPatient/referralRegistrationSlice";
import dataGridSliceReducer from "./components/shared/dataGrid/dataGridSlice";

const rootReducer = combineReducers({
  app: appSliceReducer,
  reportSearchFilterState: reportSearchFilterSliceReducer,
  referralRegistrationState: referralRegistrationSliceReducer,
  dataGridState: dataGridSliceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
