// #region Declarations

import { lazy } from "react";

/* eslint-disable @typescript-eslint/naming-convention */
export enum ApplicationRoutes {
  Login = "/login",
  Validate = "/validate/:token",
  SetPassword = "/setPassword",
  DirectAccess = "/directAccess",
  Home = "/portal/home",
  Dashboard = "/portal/dashboard",
  Resources = "/portal/resources",
  Newsletter = "/portal/newsletter",
  MyData = "/portal/myData",
  MyImpact = "/portal/myImpact",
  ContactUs = "/portal/contactUs",
  MyAccount = "/portal/myAccount",
  Portal404 = "/page404",
  ForgotPassword = "/forgotPassword",
  Register = "/register",
  Completion = "/portal/completion",
  DirectConsent = "/directConsent",
  ParticipantSearch = "/portal/participantSearch",
  CommentReviewReport = "/portal/commentReviewReport",
  DirectCompletion = "/directCompletion",
  RegisterPatient = "/registerPatient",
  OptOut = "/optOut",
  Participants = "/portal/participants",
  SelfRegistration = "/selfRegistration",
  DownloadConsentDocument = "/downloadConsentDocument",
  GasBuilder = "/portal/participants/gas/:identityId/builder/:gasSurveyId",
  GasScore = "/portal/participants/gas/:identityId/gasScore/:gasSurveyId",
  Gas = "/portal/participants/gas/:identityId",
  ViewSubmissions = "/portal/viewSubmissions/viewSubmissions/:id?",
  ReferralRegistration = "/referralRegistration/:appname",
}
export const UnAuthenticatedRoutes = [
  {
    path: ApplicationRoutes.ForgotPassword,
    component: lazy(() => import("../account/forgotPassword")),
  },
  {
    path: ApplicationRoutes.Login,
    component: lazy(() => import("../account/loginPage")),
  },
  {
    path: ApplicationRoutes.Register,
    component: lazy(() => import("../account/registration")),
  },
  {
    path: ApplicationRoutes.Validate,
    component: lazy(() => import("../account/validate")),
  },
  {
    path: ApplicationRoutes.SetPassword,
    component: lazy(() => import("../account/setPassword")),
  },
  {
    path: ApplicationRoutes.DirectAccess,
    component: lazy(() => import("../account/directAccess")),
  },
  {
    path: ApplicationRoutes.DirectConsent,
    component: lazy(() => import("../account/directConsent")),
  },
  {
    path: ApplicationRoutes.OptOut,
    component: lazy(() => import("../optOut/optOut")),
  },
  {
    path: ApplicationRoutes.RegisterPatient,
    component: lazy(() => import("../registerPatient/registerPatient")),
  },
  {
    path: ApplicationRoutes.SelfRegistration,
    component: lazy(() => import("../registerPatient/selfRegistration")),
  },
  {
    path: ApplicationRoutes.DownloadConsentDocument,
    component: lazy(
      () => import("../downloadConsentDocument/downloadConsentDocument")
    ),
  },
  {
    path: ApplicationRoutes.ReferralRegistration,
    component: lazy(() => import("../registerPatient/referralRegistration")),
  },
  {
    path: ApplicationRoutes.DirectCompletion,
    component: lazy(() => import("../completion/directCompletion")),
  },
  {
    path: ApplicationRoutes.Portal404,
    component: lazy(() => import("../main/page404")),
  },
];
// #endregion
