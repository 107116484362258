import { DashboardEndPoints } from "./configEndPoints";
import { post, ApiResponse } from "../helpers";
import { DashboardTileModel } from "../models/dashboardModel";

// eslint-disable-next-line @typescript-eslint/naming-convention
const GetTiles = async (): Promise<ApiResponse<DashboardTileModel>> => {
    return post<string, DashboardTileModel>(DashboardEndPoints.GetTiles, "");
}; 

// eslint-disable-next-line @typescript-eslint/naming-convention
const DashboardApi = {
    GetTiles
};

export default DashboardApi;