import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReferralRegistration } from "../../libs/models/registrationModel";

const initialState: ReferralRegistration = {
  data: "",
  signature: "",
  isReferralRegistration: false,
};

/* istanbul ignore next */
const referralRegistrationSlice = createSlice({
  name: "referralRegistrationState",
  initialState,
  reducers: {
    onReferralRegistration: (
      state,
      action: PayloadAction<ReferralRegistration>
    ) => {
      state.data = action.payload.data;
      state.signature = action.payload.signature;
      state.isReferralRegistration = action.payload.isReferralRegistration;
    },
    onReferralRegistrationCompleted: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.data = "";
      state.signature = "";
      state.isReferralRegistration = false;
    },
  },
});
export const { onReferralRegistration, onReferralRegistrationCompleted } =
  referralRegistrationSlice.actions;
export default referralRegistrationSlice.reducer;
