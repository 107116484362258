import { ResourcesEndPoints } from "./configEndPoints";
import { post, ApiResponse, get } from "../helpers";
import {
    ResourcesRequest,
    ResourceHeadlines,
    Resources,
    DBLockResource,
    AnonymousLanguageResourceSearchDetails,
    LanguageResourceTextDetails,
    CommentReviewOutcome
} from "../models/resourceModel";

const getResources = async (
    resourcesRequest: ResourcesRequest
): Promise<ApiResponse<Resources[]>> => {
    return post<ResourcesRequest, Resources[]>(ResourcesEndPoints.GetResources, resourcesRequest);
};

const getResourceHeadlines = async (
    resourcesRequest: ResourcesRequest
): Promise<ApiResponse<ResourceHeadlines[]>> => {
    return post<ResourcesRequest, ResourceHeadlines[]>(ResourcesEndPoints.GetResourceHeadlines, resourcesRequest);
};

const getResourceNewsLetters = async (
    resourcesRequest: ResourcesRequest
): Promise<ApiResponse<ResourceHeadlines[]>> => {
    return post<ResourcesRequest, ResourceHeadlines[]>(ResourcesEndPoints.GetResourceNewsLetters, resourcesRequest);
};

const getDBLockResource = async (
  localeId: string
): Promise<ApiResponse<DBLockResource>> => {
  return post<string, DBLockResource>(ResourcesEndPoints.GetDBLockResource, localeId);
};


  const getPermittedAnonymousResourcesByLocaleId = async (
  anonymousLanguageResourceSearchDetails: AnonymousLanguageResourceSearchDetails 
) : Promise<ApiResponse<LanguageResourceTextDetails>> => {
  return post<AnonymousLanguageResourceSearchDetails, LanguageResourceTextDetails>(
    ResourcesEndPoints.GetPermittedAnonymousResourcesByLocaleId,
    anonymousLanguageResourceSearchDetails
  )
}

const getCommentReviewOptions= async (
): Promise<ApiResponse<Array<CommentReviewOutcome>>> => {
  return get<Array<CommentReviewOutcome>>(ResourcesEndPoints.getCommentReviewOptions);
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ResourcesApi = {
    getResources,
    getResourceHeadlines,
    getResourceNewsLetters,
    getDBLockResource,
    getPermittedAnonymousResourcesByLocaleId,
    getCommentReviewOptions,
};

export default ResourcesApi;