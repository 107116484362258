// #region Imports
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useClientAppSettings, useToken } from "../../libs/hooks";
// #endregion

// #region Component
// eslint-disable-next-line @typescript-eslint/naming-convention
export default function AuthenticatedRoute(props: RouteProps): JSX.Element {
  const { app } = useClientAppSettings();
  const { token } = useToken();
  const { render, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) =>
        token && render ? (
          render(props)
        ) : (
          <Redirect to={`/login?redirect=/${app.AppName}`} />
        )
      }
    />
  );
}
// #endregion
