// #region Imports
import { Redirect } from "react-router-dom";
import LanguageContext from "../../libs/context/LanguageContext";
import { useClientAppSettings, useToken } from "../../libs/hooks";
// #endregion

// #region Declarations
type Props = {
  matchPath: string;
};
// #endregion

// #region Component
/**
 * Stores the client app name - e.g. BaseNhsDev, FFB to local storage and redirects to the root "/"
 * Any other client specific settings can be handled here - e.g locale
 * Make any API call from there to load any client specific settings from the back end if needed
 * @param props
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export default function InitialiseClient(props: Props): JSX.Element {
  const { setToken } = useToken();
  const { app, setClientApp } = useClientAppSettings();
  const { matchPath } = props;
  const appNameFromPath = matchPath.replace("/", "");
  // update local storage only if the app name has changed
  if (appNameFromPath !== app.AppName) {
    setToken({ token: "" });
    setClientApp({
      AppName: appNameFromPath,
      ClientName: "",
      ClientFullName: "",
      ClientLogo: "",
      CopyrightYear: "",
      ForceReadOnly: false,
      DisableSelfRegistration: false,
      DatabaseLock: true,
      DatabaseLockText: "",
      siteLanguage: "",
      portalDefaultLanguage: "",
      ShowForgotPasswordLink: false,
    });
  }
  return (
    <LanguageContext.Consumer>
      {({ locale, updateLocale }) => {
        return <Redirect to="/" />;
      }}
    </LanguageContext.Consumer>
  );
}
// #endregion
