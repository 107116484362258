import { createBrowserHistory } from "history";
import { AccountApi } from "../services";

const history = createBrowserHistory({
  forceRefresh: true,
  basename: process.env.REACT_APP_BASEPATH,
});

function signoutRedirectCustom(): void {
  AccountApi.logoutUser().then((resp) => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("isDirectAccess");
    const clientAppValue = JSON.parse(
      sessionStorage.getItem("ClientApp") as string
    );
    history.push("/" + clientAppValue.AppName);
  });
}
const signOutService = {
  signoutRedirectCustom,
};

export default signOutService;