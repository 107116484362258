import { useState } from "react";

export default function useClientApp(): {
  getClientAppSettings: () => {
    AppName: string;
    ClientName: string;
    ClientFullName: string;
    ClientLogo: string;
    CopyrightYear: string;
    ForceReadOnly: boolean;
    DisableSelfRegistration: boolean;
    DatabaseLock: boolean;
    DatabaseLockText: string;
    siteLanguage: string;
    portalDefaultLanguage: string;
    ShowForgotPasswordLink: boolean;
  };
  setClientApp: (clientApp: {
    AppName: string;
    ClientName: string;
    ClientFullName: string;
    ClientLogo: string;
    CopyrightYear: string;
    ForceReadOnly: boolean;
    DisableSelfRegistration: boolean;
    DatabaseLock: boolean;
    DatabaseLockText: string;
    siteLanguage: string;
    portalDefaultLanguage: string;
    ShowForgotPasswordLink: boolean;
  }) => void;

  app: {
    AppName: string;
    ClientName: string;
    ClientFullName: string;
    ClientLogo: string;
    CopyrightYear: string;
    ForceReadOnly: boolean;
    DisableSelfRegistration: boolean;
    DatabaseLock: boolean;
    DatabaseLockText: string;
    siteLanguage: string;
    portalDefaultLanguage: string;
    ShowForgotPasswordLink: boolean;
  };
} {
  const getClientApp = (): {
    AppName: string;
    ClientName: string;
    ClientFullName: string;
    ClientLogo: string;
    CopyrightYear: string;
    ForceReadOnly: boolean;
    DisableSelfRegistration: boolean;
    DatabaseLock: boolean;
    DatabaseLockText: string;
    siteLanguage: string;
    portalDefaultLanguage: string;
    ShowForgotPasswordLink: boolean;
  } => {
    let clientAppString: string | null = sessionStorage.getItem("ClientApp");
    if (clientAppString == null) clientAppString = "{}";
    return JSON.parse(clientAppString);
  };

  const [app, setApp] = useState(getClientApp());

  const saveClientApp = (clientApp: {
    AppName: string;
    ClientName: string;
    ClientFullName: string;
    ClientLogo: string;
    CopyrightYear: string;
    ForceReadOnly: boolean;
    DisableSelfRegistration: boolean;
    DatabaseLock: boolean;
    DatabaseLockText: string;
    siteLanguage: string;
    portalDefaultLanguage: string;
    ShowForgotPasswordLink: boolean;
  }) => {
    sessionStorage.setItem("ClientApp", JSON.stringify(clientApp));
    setApp(clientApp);
  };

  return {
    getClientAppSettings: getClientApp,
    setClientApp: saveClientApp,
    app,
  };
}
